export const defaultStyle = {

}

export const CONTACTUS_FORM = {
    FormParent: "max-w-[1200px] mx-auto md:flex flex-wrap my-[18px] contact-form",
    FormText: "md:max-w-[33.333%] p-30 leftContent",
    ContactUsForm: "md:max-w-[66.66%] border-l-[1px] border-[#ddd] text-black",
    text: "text-[#070707] [&>h4]:font-medium [&>h4]:text-20 [&>h4]:mb-8 [&>p]:mb-16 [&>p]:leading-[25px]"

}