import React, { useState, Fragment } from "react"
import Select from "../UI/Input/Select"
import ImageCard from "../UI/ImageCard/ImageCard"
import * as styles from "./ProductListing-tw-styles"
import PropTypes from "prop-types"
import {
  PRODUCT_TYPES,
  NO_PRODUCTS_FOUND,
} from "../../constants/label.constants"
import Typography from "../UI/Typography/Typography"

const ProductListing = props => {
  const { productFilter, productsCollection, title, description } = props
  const style = styles?.ProductListingStyle
  const select = { options: productFilter?.dropdownItemsCollection?.items }
  const [ProductList, setProductList] = useState(productsCollection?.items)
  const [selectedValue, setselectedValue] = useState(select?.selectedValue)


  function filterproduct(product) {
    setselectedValue(product)
    if (product === PRODUCT_TYPES?.ALL_PRODUCT) {
      setProductList(productsCollection?.items)
    } else {
      const newFilter = productFilter?.dropdownItemsCollection?.items.find(
        obj => obj?.value === product
      )
      setProductList(newFilter?.productsCollection?.items)
    }
  }

  return (
    <div>
      <div  className={style.title}>{title}</div>
      <div className={style.selectdiv}>
        <Select
          {...select}
          gaEventClass = "event_filter_click"
          selectedValue={selectedValue}
          onChange={e => filterproduct(e.target.value)}
          style={styles?.SelectStyles}
        />
      </div>
      <div className={style.linktext}>
        <Typography content={description} />
      </div>
      <div className={style.carddiv}>
        {ProductList.length > 0 ? (
          ProductList?.map((option, index) => (
            <ImageCard
              images={option?.productImage}
              title={option?.productName}
              text={option?.casePack}
              description={option?.caseUpc}
              key={index}
              style={styles?.ImageCardStyles}
            />
          ))
        ) : (
          <h4>{NO_PRODUCTS_FOUND}</h4>
        )}
      </div>
    </div>
  )
}

ProductListing.propTypes = {
  productFilter: PropTypes.object,
  productsCollection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  description: PropTypes.string,
}

export default ProductListing