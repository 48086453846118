const query = (id, isPreview) => `
{
  formContainer(id: "${id}", preview: ${isPreview}) {
    variant
    formType
    form {
        heading
        jsonEditor
      }
  }
}

  

  `
module.exports = { query }
