export const defaultStyle = {
    layoutContainer: "imagelist max-w-full relative lg:mx-auto my-0 ",
    heading: "text-center font-ProxNarrow lg:text-4xl text-[40px] lg:w-full w-2/3 mx-auto leading-[50px] text-white pb-40 tracing-[1px] [&>p]:text-[40px]",
    description: "chefDesc text-2xl md:w-[750px] mx-auto my-0 lg:text-3xl leading-[30px] lg:leading-10 text-center text-white px-10",
    anchor: "anchor",
    listItem: "listItem lg:w-[360px] lg:max-w-full even:pt-[0%]  mx-auto px-[2px] lg:px-auto overflow-hidden",
    list: " zigZagImg max-w-full lg:w-[1170px] w-full mx-auto  max-w-full grid md:grid-cols-3 md:gap-13 gap-10 lg:gap-[24px] grid-cols-2 md:px-23  lg:mx-auto md:mx-0 px-15 md:px-auto relative mt-10 lg:max-h-[860px]",
    textContainer: "bg-darkBlue py-30 text-center ",
    imgMain: "imgMain rounded-[1rem]",
  }
  export const ProductListingStyle ={
    mainContainer:"flex justify-center",
    selectdiv: "text-center",
    carddiv: "flex justify-center content-between flex-wrap container mx-auto lg:max-w-[1200px]",
    title:"text-[#333333] font-Bwsurco text-center mb-[2rem]",
    linktext: "text-center container mx-auto lg:max-w-[880px] max-w-[90%] md:max-w-[90%] mx-auto p-[16px] mt-[2rem] mb-[1rem] text-[#333333] font-Bwsurco text-[1.75rem] [&_a]:text-[#4F2170] [&_a]:underline bg-[#F0F0F0] border-[3px] border-[#724D8D]",
  }
  
  export const SelectStyles = {
  select: "text-[#4F2170] font-bold border-[#4F2170] border px-[1rem] py-[.4rem] my-[1rem] w-full max-w-[250px] bg-no-repeat"
  };
  
  export const ImageCardStyles = {
  imageBoxWrpr: "imageBoxWrpr border-1 border-[#cccccc] p-[1rem] m-2 max-w-[250px] w-[250px] h-[315px]",
  imgContainer: "imgContainer flex w-full",
  imgWrpr: " imgWrpr w-full max-h-[200px] max-w-[200px] mx-auto",
  heading: "text-left text-[1.25rem] text-[#4F2170] leading-tight font-Bwsurcothick font-bold",
  linkWrpr: "whitespace-pre-line",
  contentWrapr: "text-left leading-[1.771rem] text-[14px] font-Bwsurcothin text-[#333333]",
  }