export const styles = {
    mainContainer: "w-full lg:float-left",
    rowWrapper: "flex flex-wrap justify-between sm:mx-15 border-b-[10px] border-[#4f2170] mr-[0px] pl-[10px] sm:pl-0",
    rowWrapperAbout: "flex flex-wrap justify-center align-center custom-padding",
    brandlogos: "my-8 py-8 mx-0 text-center flex content-center justify-between flex-wrap px-0",
    linkWrapper: "no-underline bg-transparent cursor-pointer hover:decoration-indigo-600 mr-[1rem] lg:mr-[.5rem]",
    imgWrpr: "px-0 align-middle border-none lg:py-8",
    imgWrprHalls: "px-0 align-middle mx-[2rem] md:mx-[0rem] border-none lg:py-8",
    brandwrappertitle: "font-Bwsurco text-center text-[1.75rem] text-[#4f2170] padding-custom",
    rowAbout: "flex flex-wrap justify-between lg:float-left lg:w-[50%]",
    row: "flex flex-wrap justify-between xxl:px-[7.75rem] sm:px-[1.5rem] xxl:min-w-[970px] lg:px-[2rem]"
}
