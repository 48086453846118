import React from 'react'
import PropTypes from "prop-types"
import {styles} from  './InfoGramContent-tw-styles'

const InfoGramContent = props => {
   const { heading, description, insideContent} = props
    //const style = styles
   return (
    <div>
        <iframe src={insideContent} height={7600} width="100%" scrolling='no'>
        </iframe>
    </div>
  )
}


InfoGramContent.propTypes = {
    heading:PropTypes.string,
    description:PropTypes.string,
    insideContent: PropTypes.string
}

export default InfoGramContent