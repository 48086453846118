import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { RECIPE_FILTER_CONTAINER as style } from "./RecipeFilter-tw-styles"
import { labels } from "../../../constants/recipeListing.constants"
import { imageConstants } from "../../../constants/image.constants"
import Image from "../../UI/Image/Image"
import Radio from "../../UI/Input/Radio"
import CheckBox from "../../UI/Input/CheckBox"
import { useSelector, useDispatch } from "react-redux"
import { updateFilters } from "../../../redux/actions/recipesListControlsAction"
import { updatePageUrl } from '../../../helpers/search'

const RecipeFilter = props => {
  const { recipeFilters: recipeFiltersInput, closeModal } = props
  const { filterGroupNames } = labels?.filterRecipes
  const dispatch = useDispatch()
  const recipeListReducer = useSelector(state => state.recipeListReducer)
  const { classFilters, clafeObj } = recipeListReducer

  const [filtersObject, setFiltersObject] = useState({ ...classFilters })
  const [clafe, setClafe] = useState({...clafeObj})

  // on change RecipeType value
  const onRecipeTypeChange = (e, type) => {
    setFiltersObject({ ...filtersObject, PhiladelphiaType: [{ ...type }] })
  }

  // on Sector and DishType filter values change
  const onFiltersChange = (filterType, category) => {
    const newObject = {...filtersObject}
    const arr = newObject[filterType]
    const index = arr?.findIndex(ele => ele.CategoryId === category?.CategoryId)
    index > -1 ? arr?.splice(index, 1) : arr?.push({ ...category })
    newObject[filterType] = [...arr]
    setFiltersObject({ ...newObject})
  }

  // on filters submit
  const onSubmitFilters = () => {
    dispatch(updateFilters({classFilters:filtersObject, clafeObj: clafe}))
    updatePageUrl({...recipeListReducer, classFilters:filtersObject})
    closeModal()
  }

  // reset selected filters
  const resetFilters = () => {
    setFiltersObject({
      PhiladelphiaType: [],
      DishType: [],
      Sector: [],
    })
    setClafe({})
  }

  // build PhiladelphiaType filters
  const buildPhiladelphiaTypeFilter = () => {
    return (
      <div className={style?.groupCtnr}>
         <div className="font-ProxRegular text-[15px] text-white flex justify-end items-center mb-[1px] pr-10 md:hidden h-50 bg-darkBlue" onClick={closeModal}>   Close <label className="closeIcon ml-10 cursor-pointer  text-[15px] w-[25]"> </label>
       
       </div>
        <div className={`${style?.groupName} filter-recipe-type text-[1.8em] bg-darkBlue text-white h-50 flex items-center pl-20`}>
          <span className="font-ProxBold text-[17px] ml-20 w-3/4">{filterGroupNames?.typeOfPhiladelphia} </span>
         
         <div className="font-ProxRegular text-[17px]  w-1/4 flex justify-end mr-10 hidden md:flex justify-end" onClick={closeModal}>   Close <label className="closeIcon ml-10 cursor-pointer  text-[17px] w-[25]"> </label>
       
         </div>
        </div>
        <div className={style?.popoupFilter}>
         <div className={style?.imageBox}><Image contentfulImage={imageConstants?.recipeFilterType} /></div> 
         <div  className={style?.readionBtnBox}>
          {recipeFiltersInput?.PhiladelphiaType?.length && recipeFiltersInput?.PhiladelphiaType?.map(type => {
            return (
              <Radio
                key={type?.CategoryId}
                value={type?.CategoryId}
                label={type?.CategoryName}
                name={labels?.philadelphiaTypeName}
                onChange={e => {
                  onRecipeTypeChange(e, type)
                }}
                className={style?.radioBtn}
                checked={
                  filtersObject?.PhiladelphiaType?.length && filtersObject?.PhiladelphiaType[0]?.CategoryId ===
                  type?.CategoryId
                }
              />
            )
          })}
        </div>
        </div>
      </div>
    )
  }

  // build DishType ans Sector filters
  const buildDishAndSectorFilters = (filterType) => {
    return (
      <div className={style?.groupCtnr}>
        <div className={`${style?.groupName}  text-[1.8em] bg-darkBlue text-white h-50 flex items-center pl-10`}>
          <span className="filter-sector text-white w-[50px] h-[50px]"></span>
          <span  className="font-ProxBold text-[17px] ml-20">{filterGroupNames?.sector}</span>
          </div>
          <div className="">
          <div className="flex flex-wrap justify-between recepieCheckBox lg:m-27 lg:p-0 p-15">
            {recipeFiltersInput[filterType] && recipeFiltersInput[filterType].map(category => {
              if(category?.CategoryId === "4954"){
                  return null
               }
              return (
                <CheckBox
                  key={category?.CategoryId}
                  label={category?.CategoryName}
                  value={category?.CategoryId}
                  name={category?.CategoryName}
                  onChange={e => {
                    onFiltersChange(filterType, category)
                  }}
                  checked={Boolean(
                    filtersObject[filterType]?.find(
                      ele => ele?.CategoryId === category?.CategoryId
                    )
                  )}
                />
                
              )
            })}
        </div>
          </div>
        
      </div>
    )
  }

  return (
    <div className={style?.container} onClick={(e) => {e.stopPropagation()}}>
      <div>
        {recipeFiltersInput?.PhiladelphiaType && buildPhiladelphiaTypeFilter()}
        {recipeFiltersInput?.DishType && buildDishAndSectorFilters('DishType')}
        {recipeFiltersInput?.Sector && buildDishAndSectorFilters('Sector')}
      </div>
      <div className=" flex flex-wrap pb-30">
        <div className="font-ProxNarrow text-[18px] block w-[300px] max-w-full flex items-center min-h-[2.3em] px-[0.75em] my-[0.5em] mx-[auto] leading-[1.4em] text-center no-underline border-2 border-darkBlue bg-white text-darkBlue tracking-[2px] cursor-pointer relative z-[1]  rounded-[100px] overflow-hidden flex justify-center" onClick={resetFilters}>
          <span className="filter-reset-icon hidden md:inline-block text-[32px] mr-30 relative top-[5px]"></span>
          {labels?.filterRecipes?.resetFilters}
        </div>
        <div className="buttonEffect2" onClick={onSubmitFilters}>
          {labels?.filterRecipes?.applyFilters}
        </div>
      </div>
    </div>
  )
}

RecipeFilter.propTypes = {
  recipeFilters: PropTypes.object,
  closeModal: PropTypes.func,
}

export default RecipeFilter
