import React from "react"
import { PASSWORD } from "../../../constants/rules.constants"
import * as styles from "./Input-tw-styles"
import PropTypes from "prop-types"

function Input(props) {
  const {
    type,
    label,
    id,
    name,
    placeholder,
    isRequired,
    variant,
    errorMessage,
    onChange,
    button,
    onBlur,
    isValid,
    maxLength,
    value,
    helpText,
  } = props
  const style = styles[variant] || styles.defaultStyle

  return (
    <div className={style?.inputContainer}>
      <label htmlFor={name} className={style?.labelStyle}>
        {label}
        {`${isRequired ? "*" : ""}`}
      </label>

      <div className={style?.inputWrpr}>
        <input
          type={type}
          className={`${style?.text} ${
            !isValid  ? style?.error : "border-2 border-inputBrdr"
          }`}
          id={id}
          placeholder={placeholder}
          name={name}
          onChange={event => onChange(event, id)}
          onBlur={event => onBlur(event, id)}
          maxLength={maxLength}
          value={value}
        />
      </div>
      {name === PASSWORD && (
        <button
          id={button.id}
          name={button.name}
          className=""
          onClick={event => onChange(event, button.id)}
        >
          +
        </button>
      )}
      {errorMessage && <div>{errorMessage}</div>}
      {helpText && <div className="help-text">{helpText}</div>}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  button: PropTypes.object,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  helpText: PropTypes.string,
}

export default Input
