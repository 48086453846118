const query = id => `
{
    productListing (id: "${id}") {
    internalTitle
    title
    description
    productFilter{
      dropdownItemsCollection(limit:50){
        items{
          label
          value
          productsCollection(limit:11){
            items{
              productName
              productImage{
                altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
              }
              caseUpc
              casePack
            }
          }
        }
      }
    }
    productsCollection(limit:50){
      items{
        productName
        productImage{
                altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
              }
        caseUpc
        casePack
      }
    }
  }
}
`

module.exports = {query}