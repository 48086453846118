import React, { useState, useEffect } from "react"
import Button from "../Button/Button"
import PropTypes from "prop-types"
import { validate } from "../../../helpers/FormValidations"
import RenderField from "../../../helpers/RenderField"
import * as styles from "./FindDistributors-tw-styles"
import { labels } from "../../../constants/label.constants"
import axios from "axios"
import { gaEventClick, findDistributor } from "../../../constants/gtm.constants"

const FindDistributors = props => {
  const { jsonEditor, formStyle, heading } = props

  const [form, setForm] = useState(jsonEditor)
  const [error, setError] = useState({})
  const style = styles.defaultStyle
  const [submitError, setSubmitError] = useState(false)
  const [distributorList, setDistributorList] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const GTINNo = urlParams.get("GTIN") ? urlParams.get("GTIN") : 0
    const zipCode = urlParams.get("Zipcode")
    axios
      .get(
        `${process.env.GATSBY_DISTRIBUTOR_API}/${GTINNo}/${zipCode}/10/1/1/1`
      )
      .then(res => {
        setDistributorList(res.data)
        setShowSuccess(true)
      })
      .catch(error => {
        console.warn("error", error)
      })
  }, [])
  const handleChange = (event, id) => {
    const validateField = validate({ ...form[id], value: event.target.value })
    setError(err => ({
      ...err,
      [validateField?.name]: validateField?.error,
    }))
    setForm(field => {
      return {
        ...field,
        [id]: { ...field[id], value: event.target.value },
      }
    })
  }

  function Validate(orgObj, tempObj) {
    return Object.entries(orgObj)?.map(([key, value]) => {
      tempObj[key] = validate(value)
      setError(err => ({ ...err, [value?.name]: value?.error }))
      return tempObj[key]?.isValid
    })
  }

  function handleSubmit() {
    const validateField = form
    const isfieldsValid = Validate(form, validateField)
    if (!isfieldsValid.includes(false)) {
      setSubmitError(false)
      const tempData = Object.entries(form).map(([key]) => {
        return { [key]: form[key]?.value }
      })
      const formData = Object.assign({}, ...tempData)
      const { zipcode, GTINNumber } = formData

      const GTINNo = GTINNumber ? GTINNumber : 0
      if (typeof window !== "undefined") {
        const queryParams = new URLSearchParams(window?.location?.href)

        queryParams.set("GTIN", GTINNo)
        queryParams.set("Zipcode", zipcode)
        const GTIN = GTINNo ? GTINNo : ""
        const params = `GTIN=${GTIN}&Zipcode=${zipcode}`
        history.replaceState(null, null, "?" + params)
      }
      axios
        .get(
          `${process.env.GATSBY_DISTRIBUTOR_API}/${GTINNo}/${zipcode}/10/1/2/2`
        )
        .then(res => {
          setDistributorList(res.data)
          setShowSuccess(true)
        })
        .catch(error => {
          console.warn("error", error)
        })
    } else {
      setSubmitError(true)
    }
  }
  function renderFields(list) {
    return Object.entries(list)?.map(([key, value]) => (
      <RenderField
        key={key}
        id={key}
        field={value}
        onBlur={handleChange}
        handleChange={handleChange}
        error={error}
        variant={form[key]?.variant}
      />
    ))
  }

  return (
    <div className={style?.mainContainer}>
      <div className={style?.subscriptionContainer}>
        <div className={style?.middleContainer}>
          <div>{heading}</div>
          <div className={style?.formWrapper}>
            {renderFields(form)}
            {submitError && (
              <div className={style?.submitError}>
                {labels.submitErrorMessage}
              </div>
            )}
            <Button
              text={labels.Find_Disttributors_Text}
              onClick={handleSubmit}
              className={`${style?.submitButton} ${gaEventClick}`}
              dataActionDetail={findDistributor}
            />
          </div>
          <div>
            {distributorList && distributorList.length ? (
              distributorList.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    id="DistributorDetails"
                    className="flex flex-wrap w-full border-b-1 border-[#cccccc] pb-[10px] mb-[20px] mt-[40px]"
                  >
                    <div className="distributorContent w-[60%]">
                      <h1 className="text-[22px]">
                        {item?.DistributorDescription}
                      </h1>
                      <p className="text-[16px]">
                        {`${item?.DistributorCity} ${item?.DistributorStateCode} ${item?.DistributorZipCode}`}
                      </p>
                    </div>
                    <div className="DistributorDetailsMap">
                      <div className="grid_4 omega geo">
                        <div className="distance text-[18px]">
                          {item?.DistributorDistance}&nbsp;miles
                        </div>
                        <div className="map_selection text-[#4F2170] font-bold w-[240px]">
                          <a
                            id="map_google"
                            className="underline"
                            href={`http://maps.google.ca/maps?q=${item?.DistributorCity},${item?.DistributorStateCode} ,${item?.DistributorZipCode}`}
                          >
                            Google
                          </a>
                          <br />

                          <a
                            id="map_yahoo"
                            className="underline"
                            href={`http://maps.yahoo.com/#q=${item?.DistributorCity},${item?.DistributorStateCode} ,${item?.DistributorZipCode}&amp;conf=1&amp;start=1&amp;zoom=16&amp;mvt=m&amp;trf=0&amp;tt=`}
                          >
                            Yahoo
                          </a>
                        </div>
                      </div>
                    </div>
                    {item?.DistributorPhone.trim().length > 0 && (
                      <div className="DistributorPhone">
                        <div className="DistributorPhone_Inner bg-[#4F2170] text-white text-[16px] h-[36px] leading-[34px] cursor-pointer font-bold w-[200px] justify-center flex mt-[20px] md:mt-0">
                          {item?.DistributorPhone}
                        </div>
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div>{showSuccess && <h2>No results found</h2>}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FindDistributors.propTypes = {
  jsonEditor: PropTypes.object,
  formStyle: PropTypes.object,
  style: PropTypes.object,
  heading: PropTypes.string,
}

FindDistributors.defaultProps = {
  jsonEditor: {},
  formStyle: {},
  style: {},
}

export default FindDistributors
