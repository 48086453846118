import React from "react"
import PropTypes from "prop-types"

const SearchInput = props => {
  const {
    placeholder,
    onFocus,
    onBlur,
    onSearchSubmit,
    style,
    searchTerm,
    onChange,
  } = props

  const onKeyDown = e => {
    if (e.key === "Enter" && searchTerm) {
      onSearchSubmit()
    }
  }

  return (
    <div className={style?.formContainer}>
      <span onClick={onSearchSubmit} className={`${style?.image} icon-search`}/>
      <div className={style?.form}>
        <input
          placeholder={placeholder}
          value={searchTerm || ""}
          onChange={onChange}
          className={style?.input}
          autoComplete="on"
          onFocus={onFocus}
          onBlur={onBlur}
          data-action-detail={searchTerm}
          onKeyDown={onKeyDown}
          name="search-box"
        ></input>
      </div>
    </div>
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  style: PropTypes.object,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func,
  searchType: PropTypes.string,
}

export default SearchInput
