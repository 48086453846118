export const input_type = {
  text: " form-control block w-full px-3 py-1.5 text-base font-normal   text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  number:
    "form-control block w-full  px-3  py-1.5  text-base  font-normal  text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300  rounded-full  transition  ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  email:
    "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
}

export const formInputStyle = {
  inputContainer: "lg:mb-3 mb-27 xl:w-96",
  labelStyle: "inline-block p-5 text-lg leading-[22px] text-white  ",
  text: "form-control block w-full p-13 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid  rounded-[25px]  border-solid border-2 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none formInput",
  select:
    "form-select bg-dropDownArrow bg-no-repeat bg-right appearance-none block w-full p-14 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border border-solid  rounded-[25px] transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none bg-[94% center]  bg-[0% 96%]",
  chkWrpr: "flex my-20 odd:pb-20 relative",
  checkBox:
    "form-check-input before:h-[35px] before:w-[35px] absolute before:rounded-[0.3rem] before:bg-white custCheckBox before:absolute before:border-2  rounded-[20px] before:content-['']  focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer formInput before:border-inputBrdr",
  checkBoxText:
    "inline-block p-5 text-[15px] leading-[21px] text-white lg:w-[455px]  lg:pr-0 w-full pl-50 aggrementCheckbox",
  error: "border-2 border-red-600 error",
}

export const defaultStyle = {
  inputContainer: "lg:mb-3 mb-27 xl:w-96 zip-search",
  labelStyle: "",
  text: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "bg-[url('https://images.ctfassets.net/h58rr2tk3rzx/1p3BgXeqv1EnXXAYihvMNv/a3c3c2b3089eddd41ec1a4804841f00f/download.svg_1.svg')] bg-no-repeat bg-[length:10px_25px] focus:outline-none focus:border-[1px] bg-white md:bg-white focus:border-blur-sm",
  chkWrpr: "flex mb-20",
  checkBox: "",
  checkBoxText: "",
  error: "border border-[#4F2170] focus:border-[#a3caf2] py-2 px-[1rem] cursor-pointer min-w-[250px] font-Bwsurcothin font-bold",
}

export const signUpStyle = {
  labelStyle: "inline-block text-sm mb-2 text-[#2b2b2b] font-bold",
  inputContainer: "lg:mb-3 mb-27 mt-40 xl:w-96",
  inputWrpr: " border-transparent border-solid border-2  inputShadow",
  text: "form-control block w-full py-13 px-20 text-base font-normal text-gray-700  bg-white bg-clip-padding   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "",
  chkWrpr: "flex mb-20",
  checkBox: "",
  checkBoxText: "",
  error: "border-4 border-red-500",
}

export const RECIPE_SORTING = {}
