export const defaultStyle = {
    bannerContent: "relative lg:h-[650px] overflow-hidden bannerContent",
    gradBox: "lg:h-[450px] h-[324px] overflow-hidden absolute w-1/2 lg:bg-gradBgLetToRigth z-[1]",
    imageResponsive: "image w-full h-auto relative lg:static lg:top-[82px] md:top-[70px] lg:top-[0] scale-[2.2] md:scale-[1.45] lg:scale-[1] lg:left-auto md:left-[-22%] lg:left-[-52%] p-[55px] pt-[130px] pb-[45px] md:p-[0px] md:pt-[0px] md:pb-[0px]",
    txtcontent: "philyDiffTxt bg-[#4f2170e3] sm:right-[10vw] sm:absolute w-full sm:w-[450px] relative sm:top-0 my-0 mx-auto p-[20px] md:p-[45px] lg:p-[45px] sm:pl-[40px] sm:pt-[40px] text-left z-[2] font-Bwsurco",
    txtHead: "mb-[60px] leading-[1.2] text-white font-Bwsurco text-[2.25rem] lg:text-[3.5rem] pr-[200px] md:pr-[130px] ml-20 md:ml-0",
    txtDesc: "text-[1.35rem] text-white font-Bwsurco lg:px-[0] lg:mt-auto leading-[150%] lg:max-w-full mx-auto mb-[1rem]",
    textLink: " ",
    heroLink: "px-10 text-sm tracking-wide text-darkBlue font-medium before:content-['/'] before:pr-5",
  }
  