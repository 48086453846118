const query = () => `{
  footerCollection(limit: 1) {
    items {
      note
      footerLogo {
        url
        title
        description
        width
        height
      }
      socialLinksCollection(limit: 10) {
        items {
          label
          title
          url
          target
          imageType
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
      mainLinksCollection(limit: 15) {
        items {
          label
          linksCollection {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      secondaryLinksCollection(limit: 15) {
        items {
          label
          linksCollection {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      brandLinksCollection {
        items {
          url
          image {
            url
            title
            description
            width
            height
          }
        }
      }
      contactListsCollection{
        items{
          title
          contactLinksCollection(limit: 10){
            items{
              title
              label
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height

                }
            }
          }
        }
      }
    }
  }
}
`
module.exports = { query }
