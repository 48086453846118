import React, {useEffect} from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../helpers/ContentfulComponents"
import Layout from "../components/Layout/Layout"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";
let hasOTScript

const Page = ({ pageContext }) => {
  const { globalComponents, componentProps, seoData, pageType, dataLayer, scriptId, hasOneTrustScipt } =
    pageContext

  useEffect(() => {
    if (scriptId && hasOneTrustScipt && typeof window !== "undefined") {
      const oneTrustId = scriptId?.replace("otnotice-", "")
      const isProdEnv = process.env.GATSBY_ACTIVE_ENV === "production"
      window.hasOTScript = hasOneTrustScipt
      const endpoint = `https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/${oneTrustId}.json`
      setTimeout(() => {
        if (typeof window !== "undefined" && window.OneTrust?.NoticeApi) {
          OneTrust.NoticeApi.Initialized.then(async function () {
            await OneTrust.NoticeApi.LoadNotices([endpoint], isProdEnv)
            const sectionId = window.location.hash.split("#")["1"]
            if (sectionId) {
              const sectionElement = document.getElementById(sectionId)
              if (sectionElement) {
                sectionElement.scrollIntoView()
              }
            }
          })
        }
      }, 500)
    }
  }, [])

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }
  return (
    <Layout seoData={seoData} globalComponents={globalComponents}>
      {hasOneTrustScipt && componentProps?.length > 0 ? (
        <>
            {/* Container in which the privacy notice will be rendered  */}
            <div id={scriptId} className={`otnotice`}></div>
          </>
        ) : (
          <ContentfulComponents
            componentsProps={componentProps}
            pageType={pageType}
          />
        )}
    </Layout>
  )
}
export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
          src="https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          id="otprivacy-notice-script"
          defer
      ></script>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

Page.propTypes = {
  pageContext: PropTypes.shape({
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    pageType: PropTypes.string,
    dataLayer: PropTypes.object,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogType: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      canonicalLink: PropTypes.string,
      metaTitle: PropTypes.string,
      featuredImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
}

export default Page
