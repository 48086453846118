const query = (id,isPreview) => `
{
    brandWrapper (id: "${id}", preview: ${isPreview}) {
        title
       brandLogoCollection{
        items{
            url
          image{
            url
            height
            width
            title
            description
          }
          
        }
      }
    }
  }
  `

  module.exports = {query}