const query = (id, isPreview) => `{
    recipeListing(id: "${id}", preview: ${isPreview}){
        recipeClasses{
        heading
        recipeClassesCollection{
            items{
            text
            classId
            recipeImage {
                title
                url
                description
                width
                height
            }
            }
        }
        }
        recipeBook {
            heading
            description
            isReversed
            variant
            image {
              title
              description
              url
              width
              height
            }
            backgroundImage {
              title
              description
              url
              width
              height
            }
            link {
              url
              label
              target
              gaEventClass
              gaEventLabel
            }
          }
        formLink {
          url
          label
          target
          title
          scrollToElementId
          gaEventClass
          gaEventLabel
        }
    }
}`

module.exports = { query }
