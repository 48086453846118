
export const defaultStyle = {
    mainContainer: 'px-[10px] md:px-0 my-0 mx-auto  max-w-[1200px] overflow-hidden',
    subscriptionContainer: '',
    leftImageBox: 'float-left max-w-390 text-center px-10 md:w-1/2',
    leftImage: 'left-image mb-10 border-none w-full max-w-[200px] object-center md:max-w-full mx-auto',
    leftImageText: 'left-imgtext text-14 mb-12 font-normal text-[#070707] leading-22 m-0',
    middleContainer: 'middle-column flex gap-[20px] flex-col text-[#4F2170] text-[20px] text-[#4F2170] md:mx-[10px]',
    formWrapper: 'formWrapper  justify-between flex flex-col gap-[15px] md:items-streach mb-[10px] block w-full lg:flex find-distriubutor-container md:flex md:flex-row',
    submitButton: 'bg-[#4F2170] border-white  text-[15px] py-[12px] text-white leading-3 w-[55%] self-center font-bold basis-[18%] md:text-14',
    rightImageBox: 'rightImageBox float-left w-[356px] relative',
    rightImage: 'rightImage absolute right-0 w-[200px] md:w-[250px] z-100 align-middle -top-[10px] -left-[22px] -bottom-[64px] md:top-0 md:left-0 md:bottom-0 lg:-bottom-[195px]',
    formStyle: {
        inputContainer: "adnflkafd ",
        labelStyle: "inline-block p-5 text-lg leading-[22px] text-white  ",
        text: "form-control block w-full p-13 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid  rounded-[25px]  border-solid border-2 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none formInput",
    },
}

export const FIND_DISTRIBUTORS = {
    mainContainer: "main",
}

export const eligibleLogo = {
    eligibleLogo: "text-[#212529] eligibleLogo mt-20 md:pl-50 pb-15 md:pr-50 pr-30 pl-30 flex items-center gap-[15px] flex-wrap",
    eligibleLogoImage: "eligibleLogoImage w-80"
}