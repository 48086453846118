import React from 'react'
import PropTypes from "prop-types"
import { get } from '../../helpers/utils'
import {styles} from  './BrandWrapper-tw-styles'
import Link from '../UI/Link/Link'
import Image from '../UI/Image/Image'

const BrandWrapper = props => {
   const { title,brandLogoCollection,pageType} = props
   const logo = get(brandLogoCollection,"items",[])
   const style = styles

   return (
    <div className={pageType==="About Us Page" ? style?.rowAbout : style?.row }>
    <div className={style?.mainContainer}>
        { title && <h3 className={style?.brandwrappertitle}> {title}</h3>}
        <div className={pageType==="About Us Page" ? style?.rowWrapperAbout : style?.rowWrapper }>
               {logo.map((brand,i) =>{
                return(
                  <div className={style?.brandlogos} key={i}>
                      <Link url={brand?.url} className={style?.linkWrapper} >
                         <Image contentfulImage={brand?.image}  className={brand?.image?.title === 'logo halls'? style?.imgWrprHalls:style?.imgWrpr}/> 
                     </Link> 
                 </div>
               
                )
                 } )}
          </div>
        </div>
    </div>
  )
}

BrandWrapper.propTypes = {
    title:PropTypes.string,
    brandLogoCollection:PropTypes.array,
    pageType: PropTypes.string
}

export default BrandWrapper