const query = (id, isPreview) => `
{
  infoGramContent(id: "${id}", preview: ${isPreview}) {
        heading
    	  description
        insideContent
 
  }
}
`
module.exports = { query }
