import React, { useState } from "react"
import PropTypes from "prop-types"
import Form from "../UI/Form/Form"
//import EmailSubscriptionType from "../UI/EmailSubscription/EmailSubscriptionType"
import FormType from "../../constants/formType.constants"
import FindDistributors from "../UI/FindDistributors/FindDistributors"
import { get } from "../../helpers/utils"
import * as styles from "./FormContainer-tw-styles"

const FormContainer = props => {
  const { form, variant, formType } = props
  const style = styles[variant] || styles.defaultStyle

  const ContactUsForm = data => {
    const { description, jsonEditor, heading } = data
    return (
      <div className={style.FormParent}>
        <div className={style.FormText}>
          <div className={style.text} dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
        <div className={style.ContactUsForm}>
          <Form {...data} />
        </div>
      </div>
    )
  }

  return (
    <div className="form" id="formContainer">
      <div className="">
        {formType == FormType.FindDistributors ? <FindDistributors {...form} /> : null}
      </div>
    </div>
  )
}

FormContainer.propTypes = {
  form: PropTypes.object,
  variant: PropTypes.string,
  heading: PropTypes.string,
  formType: PropTypes.string,
}

export default FormContainer
