const query = (id, isPreview) => `{
  imageCardsLayout(id: "${id}", preview: ${isPreview}) {
    title
    text
    variant
    isSlider
    sliderSettings{
      slidesToShowMobile
      slidesToScrollMobile
      slidesToShowTab
      slidesToScrollTab
    }
    link {
      label
      title
      url
      target
      scrollToElementId
      gaEventClass
      gaEventLabel
      image {
        title
        description
        url
        width
        height
      }
    }
    cardsCollection {
      items {
        title
        text
        elementId
        variant
        gaEventClass
        gaEventLabel
        image {
          url
          width
          height
          title
          description
        }
        images {
          internalTitle
          altText
          imageDesktop {
            title
            description
            url
          }
          imageMobile {
            title
            description
            url
          }
          bgDesktop {
            title
            description
            url
          }
          bgMobile {
            title
            description
            url
          }
        }
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
    }
  }
}

`
module.exports = { query }
